import { Collapse, CollapseProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import { Context } from "../../.."
import XPageModal from "../../../components/topgun-ui-kit/xmodals/XPageModal"
import {Animator} from "../../../core/anims/Animator"
import { prevent } from "../../../core/utils/utils"

import styles from "../../../styles/Loyalty/LoyaltyStore.module.scss"
import styles2 from "../../../styles/Loyalty/LoyaltyStore.module.scss"

const RulesModal: FC = () => {

  const { loyaltyStore, store } = useContext(Context)

  const navigate = useNavigate()

  useEffect(() => {
    if (loyaltyStore.isRulesVisible) {
      const animator = new Animator({
        timeline: [
          {point: 400, id: "xrule1", styles: {opacity: "1", left: "0px"}},
          {point: 600, id: "xrule2", styles: {opacity: "1", left: "0px"}},
          {point: 800, id: "xrule3", styles: {opacity: "1", left: "0px"}},
          {point: 1000, id: "rule4", styles: {opacity: "1", left: "0px"}},
          {point: 1200, id: "rule5", styles: {opacity: "1", left: "0px"}},
          {point: 1400, id: "rule6", styles: {opacity: "1", left: "0px"}},
          {point: 1600, id: "rule7", styles: {opacity: "1", left: "0px"}},
          {point: 1800, id: "rule8", styles: {opacity: "1", left: "0px"}},
          {point: 2000, id: "rule9", styles: {opacity: "1", left: "0px"}},
          {point: 2200, id: "rule10", styles: {opacity: "1", left: "0px"}}
        ]
      })
  
      animator.run()
    } else {
      const animator = new Animator({
        timeline: [
          {point: 0, id: "xrule1", styles: {opacity: "0", left: "-200px"}},
          {point: 10, id: "xrule2", styles: {opacity: "0", left: "-200px"}},
          {point: 20, id: "xrule3", styles: {opacity: "0", left: "-200px"}},
          {point: 30, id: "rule4", styles: {opacity: "0", left: "-200px"}},
          {point: 40, id: "rule5", styles: {opacity: "0", left: "-200px"}},
          {point: 50, id: "rule6", styles: {opacity: "0", left: "-200px"}},
          {point: 60, id: "rule7", styles: {opacity: "0", left: "-200px"}},
          {point: 70, id: "rule8", styles: {opacity: "0", left: "-200px"}},
          {point: 80, id: "rule9", styles: {opacity: "0", left: "-200px"}},
          {point: 100, id: "rule10", styles: {opacity: "0", left: "-200px"}}
        ]
      })
  
      animator.run()
    }
  }, [loyaltyStore.isRulesVisible])

  return (
    <>
      <XPageModal
        id="rules"
        visible={loyaltyStore.isRulesVisible}
        onClose={() => {
          loyaltyStore.setIsRulesVisible(false)
        }}
        activeZone={false}
        title={<><b className="xmtitle">Правила <strong>TOPGUN+</strong></b></>}
        content={<>
          <div className={styles.objectively}>
            <p id="xrule1" style={{opacity: "0", left: "-200px", position: "relative", transition: "all 0.4s ease"}}>После покупки подписки в системе накоплений <b>«TOPGUN+»</b> стоимость <b>всех будущих</b> оказанных услуг в <b>TOPGUN</b> будут возвращены на <b>накопительный счет рублями</b>, которые можно <b>потратить в магазине TOPGUN.</b></p>

            <ul>
              <li id="xrule2" style={{opacity: "0", left: "-200px"}}>География начислений - <b>TOPGUN РФ <strong>(некоторые филиалы не учавствуют в программе)</strong></b>, списание рублей - <b>только в интернет-магазине</b> программы лояльности</li>
              <li id="xrule3" style={{opacity: "0", left: "-200px"}}><b>Для активации и действия счета</b> необходимо иметь активную <strong>подписку</strong></li>
              <li id="rule4" style={{opacity: "0", left: "-200px"}}><b>Начисления за услуги</b> в барбершопах <b>TOPGUN</b> происходят на <b>следующий день</b> после посещения</li>
              <li id="rule5" style={{opacity: "0", left: "-200px"}}>Изначально на всех тарифах <b>скидка в магазине - 5%.</b> С каждым последующим продлением подписки, уровень скидки <strong>будет повышаться на 5%</strong> до максимального уровня <b>вашей подписки.</b> Скидка сохраняется только при продлении подписки.</li>
              <li id="rule6" style={{opacity: "0", left: "-200px"}}><b>Приглашая людей</b> в бонусную программу, вы дарите им <strong className={styles.big}>{store.userData.is_barber ? "5 000" : "3 000"}</strong> рублей, и сами получаете от <strong className={styles.big}>299</strong> до <strong className={styles.big}>1 199</strong> рублей.</li>
              {/* <li id="rule7" style={{opacity: "0", left: "-200px"}}>При регистрации по пригласительной ссылке, <b>вы получите <strong className={styles.big}>5 000</strong> рублей</b>. <b>Для того чтобы сохранить их,</b> подключите подписку в течении <b>5ти дней</b> после регистрации.</li> */}
              <li id="rule7" style={{opacity: "0", left: "-200px"}}><b>Если <strong>не продлить/отменить</strong> подписку,</b> то рубли <b>на счету <strong>останутся</strong></b>, <b>но</b> <strong>сгорит</strong> накопленная продлениями <b>скидка.</b></li>
              <li id="rule8" style={{opacity: "0", left: "-200px"}}><b>Если <strong>повысить или понизить уровень подписки сменой тарифа</strong>,</b> ваш накопленный продлениями подписки <b>уровень скидки <strong>сгорит до 10%.</strong></b></li>
              <li id="rule9" style={{opacity: "0", left: "-200px"}}><b><strong>Подписка, не подлежит возврату.</strong> Покупая подписку - вы соглашаетесь с правилами оферты, приобретаете услугу и сразу же получаете ее преимущества, часть которых имеет единоразовый характер.</b></li>
            </ul>

            <p className={styles2.spgs} id="rule10" style={{opacity: "0", left: "-200px", position: "relative", transition: "all 0.4s ease"}}><strong>*</strong> - <a href="/store/excluded" onClick={(ev) => {
              prevent(ev)
              // navigate("/store/excluded")
              loyaltyStore.setIsRulesVisible(false)
              setTimeout(() => {
                loyaltyStore.setIsExcludedVisible(true)
              }, 900)
            }}>Список филиалов</a> TOPGUN не участвующих в программе лояльности</p>
          </div>
        </>} />
    </>
  )
}

export default observer(RulesModal)