import {isElementInViewport} from "../utils/utils"

export interface StylesVar {
  opacity?: string
  bottom?: string
}

export interface TimelineItem {
  point: number
  id: string
  styles: any
}

export interface AnimatorConfig {
  timeline: TimelineItem[]
}

export class Animator {

  private config: AnimatorConfig | null = {
    timeline: [
      {
        point: 0,
        id: "",
        styles: {opacity: "1", bottom: "0px"}
      }
    ]
  }

  constructor(config: AnimatorConfig) {
    this.config = config
  }

  public run() {
    if (this.config == null || (this.config != null && this.config.timeline.length == 0)) {
      console.error("Неверная конфигурация аниматора!")
      return
    }

    this.config.timeline.forEach(timeline => {
      const anim = () => {
        const el = document.getElementById(timeline.id)
        if (el != null) {
          Object.keys(timeline.styles).forEach((style: any) => {
            if (style != "setProperty") {
              el.style[style] = timeline.styles[style]
            } else {
              el.style.setProperty(timeline.styles[style].val1, timeline.styles[style].val2)
            }
          })

          console.log(`Animator: Element #${timeline.id} successfully animated!`)
        }
      }

      if (timeline.point == 0) {
        anim()
      } else {
        setTimeout(() => {
          anim()
        }, timeline.point)
      }
    })
  }

  public setConfig(config: AnimatorConfig) {
    this.config = config
  }

  public getConfig() {
    return this.config
  }

}

export const runOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
  if (el != null) {
    if (isElementInViewport(el)) {
      let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
      el.style.opacity = `${(dell) / 100}`;
    } else {
      el.style.opacity = `0`;
    }
  }
}

export const runBottomOpacity = (el: HTMLElement | null, delay: number = 0) => {
  if (el != null) {
    if (isElementInViewport(el)) {
      let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay);
      el.style.opacity = `${(dell) / 50}`;
      let ix = ((dell) / 4) - 50;
      if (ix < 0) {
        el.style.bottom = `${ix}px`;
      } else {
        el.style.bottom = `0px`;
      }
    } else {
      el.style.opacity = `0`;
      el.style.bottom = `-50px`;
    }
  }
}