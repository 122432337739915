import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import { Context } from "../../.."
import XPageModal from "../../../components/topgun-ui-kit/xmodals/XPageModal"

import styles from "../../../styles/Loyalty/LoyaltyStore.module.scss"
import styles2 from "../../../styles/Loyalty/LoyaltyStore.module.scss"

interface DisabledTopgun {
  id?: number
  name: string
  address: string
}

interface DisabledTopgunCity {
  name: string
  items: DisabledTopgun[]
}

const closedTopguns: DisabledTopgunCity[] = [
  {
    name: "Москва",
    items: [
      {id: 1116, name: "Царицыно", address: "ул. 6-я Радиальная, д. 5 к. 3"},
      {id: 1149, name: "Каширская Плаза", address: "Каширское ш., 61Г"},
      {id: 1102, name: "Авиапарк", address: "Ходынский бульвар, 4"},
      {id: 1083, name: "Метрополис", address: "Ленинградское ш., 16А с8"},
      {id: 1082, name: "Океания", address: "Кутузовский проспект, 57"},
      {id: 1079, name: "Афимолл", address: "Пресненская наб., 2"},
      {id: 1130, name: "Капитолий", address: "ул. Проспект Вернадского, 6"}
    ]
  }, {
    name: "Пермь",
    items: [
      {id: 1239, name: "ГАЗЕТЫ ЗВЕЗДА", address: "УЛ. ГАЗЕТЫ ЗВЕЗДА, Д. 27"},
      {id: 1240, name: "НОВЫЙ ЦЕНТР", address: "УЛ. РЕВОЛЮЦИИ, 22"},
      {id: 1241, name: "ЭСПЛАНАДА", address: "ПЕТРОПАВЛОВСКАЯ УЛ., 73А"}
    ]
  }
]

const ExcludedModal: FC = () => {

  const { loyaltyStore } = useContext(Context)

  return (
    <>
      <XPageModal
        id="excluded"
        visible={loyaltyStore.isExcludedVisible}
        onClose={() => {
          loyaltyStore.setIsExcludedVisible(false)
        }}
        // theme="dark"
        activeZone={false}
        title={<>
          <b className="xmtitle">Филиалы <strong>TOPGUN</strong><br/><b style={{
            fontSize: "14px", 
            color: "rgb(0 0 0 / 77%)", 
            position: "relative", 
            fontWeight: "700",
            top: "6px",
            lineHeight: "1.5",
            display: "block",
            letterSpacing: "0px"
          }}>не участвующие в программе лояльности</b></b>
        </>}
        content={<>
          <div className={styles.objectively} style={{paddingTop: "0px"}}>
            {closedTopguns.map(cty => <div className={styles2.tg_city}>
              <h5>{cty.name}</h5>
              <div className={styles2.tg_cty}>
                {cty.items.map(item => <div className={styles2.topgun_cty}>
                  <div>{item.name}</div>
                  <div>{item.address}</div>
                </div>)}
              </div>
            </div>)}
          </div>
        </>} />
    </>
  )
}

export default observer(ExcludedModal)